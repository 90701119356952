
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap");


@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/grid";

// import configuration from the theme builder
@import "jcms_theme.css";
@import "jcms_theme.scss";


@import "themes";
@import "@nebular/theme/styles/globals";
// loading progress bar theme
@import "./pace.theme";

@import "./layout";
@import "./overrides";

// install the framework and custom global styles
@include nb-install() {
  // framework global styles
  @include nb-theme-global();
  // @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
}

/* style for arabic input */
kendo-editor[lang="ar"]>.k-editor-content>div,
kendo-editor[lang="ar"]>kendo-toolbar {
  direction: rtl !important;
  unicode-bidi: embed !important;
}

/* style for nebular window on editor forms based parameters */
.nb-edit-window {
  width: 90%;
  height: 90%;
  overflow-y: auto;
}

p, .paragraph{
  color: var(--text-basic-color) !important;
}
.k-dialog-titlebar {
  border-color: inherit !important;
  color: #fff !important;
  background-color: #009edb !important;
}

.k-icon-md{
  font-size: 32px;
}
.nb-theme-default .k-grid-table a{
  color: #36f;
}
.nb-theme-default a.header:hover {
  color: inherit;
}
